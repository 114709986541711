/*
 * @depends template=jive.people.profile.extendedProfileFields
*/
window.$j = window.$j || $; // Jive 9.19+ compatibility

if (typeof enhancedprofilepage !== 'undefined') {
    define('apps/enhancedprofile/connectionspicker', [
        'jquery',
        'jive/i18n'
    ], function($, i18n) {
        $j(document).ready(function() {
            function escapeHTML(text) {
                return !text ? text : text
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#039;');
            }

            $j('head').append("<style>.connected-users-people-picker a.jive-chooser-browse.j-select-people.j-btn-global:after{ content:'"
                    + i18n.jsGetMsg('enhancedprofile.add.connection') + "' }</style>");

            var showingMore = false;
            var savingChangedUsers = false;

            var userPicker = new jive.UserPicker.Main({
                multiple: true,
                maxSelectedCount: 15,
                $input: $j('#connectionsPicker')
            })
            userPicker.addListener('selectedUsersChanged', function(data) {
                if (data.changes.added.length && !savingChangedUsers) {
                    savingChangedUsers = true;
                    var selectedUsers = [];
                    var userList = $j('.userlist');
                    data.changes.added.forEach(function(user) {
                        if (!userList.is(':has([data-userid="' + user.id + '"])')) {
                            selectedUsers.push(user.id);
                        }
                    });
                    if (selectedUsers.length) {
                        $j.ajax({
                            type: 'POST',
                            contentType: 'application/json',
                            url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/connections',
                            data: JSON.stringify({userIds : selectedUsers}),
                            success: function() {
                                savingChangedUsers = false;
                                reloadUsers();
                            },
                            error: function(jqXHR, textStatus, errorThrown){
                                savingChangedUsers = false;
                                console.log('Error adding the connection');
                            }
                        });
                    } else {
                        savingChangedUsers = false;
                    }
                }
            });

            function getNewUsersHtml(users, maximumToDisplayByDefault) {
                var html = '';
                for (var i = 0; i < users.length; i++) {
                    var name = escapeHTML(users[i].name);
                    var userId = users[i].id;
                    var username = escapeHTML(users[i].username);

                    var userItem = '<li id="' + userId + '"';
                    if (i >= maximumToDisplayByDefault && !showingMore) {
                        userItem += ' class="connected-user-hidden"'
                    }
                    userItem += '>'
                        + '<a href="/people/' + username + '?tab=connections" aria-label="' + name
                                + '" class="j-avatar  jiveTT-hover-user" data-userid="' + userId
                                + '" data-online="false" data-presence="null" data-externalid="" data-username="' + username + '">'
                            + '<img class="jive-avatar" src="/people/' + username
                                    + '/avatar/35.png" data-username="' + username
                                    + '" height="35" data-height="35" width="35" data-random="0yydg1ogj9t" alt="Profile Photo" title="' + name + '">'
                        + '</a>'
                        + '<a href="/people/' + username + '?tab=connections" data-username="' + username
                                + '" data-userid="' + userId
                                + '" data-online="false" data-presence="null" data-externalid="" class="jive-username-link  jiveTT-hover-user  ">'
                                + name + '</a>';

                    if(_jive_current_user.ID == containerID) {
                        userItem += '<span class="removeConnection"><i class="far fa-trash-alt"></i></span>';
                    }
                    html += userItem + '</li>';
                }
                return html;
            }

            function setVisible(selector, condition) {
                if (condition) {
                    $j(selector).show();
                } else {
                    $j(selector).hide();
                }
            }

            function removeConnection() {
                userPicker.reset();
                var connected_userId = this.parentElement.id;
                $j.ajax({
                    type: 'DELETE',
                    url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/connections/' + connected_userId,
                    success: reloadUsers,
                    error: function(jqXHR, textStatus, errorThrown){
                        console.log('Error removing the connection');
                    }
                });
            }

            function reloadUsers() {
                $j.ajax({
                    dataType: 'text',
                    type: 'GET',
                    url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/connections/' + containerID,
                    success: function (data) {
                        var users = JSON.parse(data.substring(data.indexOf('[')));

                        var component = $j('div.enhanced-profile-tile.people');
                        if (users.length < 1 && component.hasClass('viewing-self-false')) {
                            component.hide();
                        }

                        var maximumToDisplayByDefault = $j('div.people div.connected-users').attr('data-maximum');

                        $j('div.connected-users ul.userlist').html(getNewUsersHtml(users, maximumToDisplayByDefault));

                        setVisible('.people-i-work-with-show-more', users.length > maximumToDisplayByDefault && !showingMore);
                        setVisible('div.connected-users-people-picker', users.length < 15);

                        $j('.removeConnection').on('click', removeConnection);
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log("Could not get list of connections for the user")
                    }
                });
            }

            $j('.people-i-work-with-show-more').on('click', function() {
                $j('div.connected-users li.connected-user-hidden').removeClass('connected-user-hidden');
                setVisible('.people-i-work-with-show-more', false);
                showingMore = true;
            });

            reloadUsers();
        });
    });
}