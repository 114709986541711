if (typeof enhancedprofilepage !== 'undefined') {
    define('apps/enhancedprofile/main', [
        'jquery',
        'apps/enhancedprofile/recent_place_activity',
        'lib/jiverscripts/src/conc/promise',
        'jive/i18n',
        'soy!jive.place.errorMessage',
        'apps/theme/lib/plugins/single_file_upload'
    ], function($, RecentPlaceActivity, Promise, i18n, errorMessage) {
        this.recentPlaceActivity = new RecentPlaceActivity();
        var fileUpload = $('#j-custom-header-background .js-file-upload');
		var TOKEN_ENDPOINT = window.parent.jive.rest.url("/legacy_token/");
		var IMAGE_ENDPOINT = window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/profile/custom-header-background';

        fileUpload.singleFileUpload();
        fileUpload.on('click.singleFileUpload', '[data-action="changeImage"], [data-action="removeImage"]', function() {
            $('.apply-custom-image').prop('disabled', true);
            setTimeout(function(){
                $("#j-static-file-upload").on('change', handleFileChange);
            }, 100);
        });

        this.processRecentPlaceActivity = function(placeData) {
            var places = [];
            for (var i = 0; i < placeData.length && i < 10; i++) {
                var place = placeData[i];
                if (place.hasOwnProperty('container')) {
                    var placeName = place.container.name;
                    var placeURL = place.container.jiveObjectURL;
                    var placeCSS = place.container.jiveObjectCSS;
                    if (!placeURL.startsWith('/people')) {
                        var identicalPlaces = places.filter(function(entry) {
                            return entry.url === placeURL;
                        });
                        if (identicalPlaces.length < 1) {
                            places.push({name: placeName, url: placeURL, css: placeCSS});
                        }
                    }
                }
            }
            updateRecentPlaceActivityComponent(places);
        }

        this.updateRecentPlaceActivityComponent = function(placeData) {
            if (placeData.length < 1) {
                $j('div.enhanced-profile-tile.place-activity').hide();
            } else {
                var placeLinks = '';
                for (var i = 0; i < placeData.length; i++) {
                    var place = placeData[i];
                    placeLinks += '<div class="activity-place"><span class="' + place.css + '"></span><a href="' + place.url + '">' + place.name + '</a></div>';
                }
                $('.place-activity div.loading').removeClass('loading').html(placeLinks);
            }
        }

        $(document).ready(function() {

            var promise = new Promise();
            promise.addCallback(function(data) {
                var containers = ((data || {}).activityStream4JS || {}).activityContainerList || {};
                processRecentPlaceActivity(containers);
            });

            recentPlaceActivity.list({
                objectType: 3,
                objectID: containerID,
                streamSource: 'profile',
                streamID: '0',
                streamChannel: 'recent',
                filterType: ['all'],
                timestamp: '0',
                includeUpdateCount: false
            }, promise);

        });

        $(document).on('click', "label.tooltip-switch i", function(e) {  
            e.preventDefault();
            e.stopPropagation()
            if ($(this).siblings(".j-pop.tooltip:not(.active)").length) 
                $(this).siblings(".j-pop.tooltip:not(.active)").show().addClass("active");
            else
                $(this).siblings(".j-pop.tooltip.active").hide().removeClass("active");
        });

        function setComponentVisibility(componentId, visibility) {
            $.ajax({
                url: '/api/core/ext/enhanced-profile-plugin/v3/profile/component-visibility/' + componentId + '/' + visibility,
                dataType: 'json',
                type: 'POST'
            })
        }

        function renderTooltipLinks() {
            $('div.tooltip span.description').each(function() {
                var tooltipContents = $(this).html();
                if (tooltipContents != '') {
                    tooltipContents = tooltipContents.replace('[link]', '<a href="');
                    tooltipContents = tooltipContents.replace('[link-new]', '<a target="_blank" href="');
                    tooltipContents = tooltipContents.replace('[/link]', '"></a>');
                    $(this).html(tooltipContents);
                }
                $(this).find('a').each(function() {
                   var url = $(this).attr('href');
                   var pipePosition = url.indexOf("|");
                   if (pipePosition > -1) {
                       var linkTitle = url.slice(0, pipePosition);
                       url = url.slice(pipePosition + 1);
                       $(this).attr('href', url);
                       $(this).text(linkTitle);
                   } else {
                       $(this).text(url);
                   }
                });
            });
            $('.j-pop.tooltip').on('click', function(e) {  
                e.stopPropagation()
            });
        }

        function handleFileChange() {
            var isValid = true;
            if (this.files[0] && !this.files[0].type.startsWith('image/')) {
                $(errorMessage({
                    errorCode: 'invalid_extension'
                })).message({
                    style: 'warn'
                });
                this.value = '';
                isValid = false;
            }

            $('.apply-custom-image').prop('disabled', !this.files[0] || !isValid);
        }

        function rePositionProfilePointsPopover(popoverParent) {
            var containerLeft = $j('.enhanced-profile-header-container').offset().left;
            var containerWidth = $j('.enhanced-profile-header-container').width();
            var popoverWidth = popoverParent.width();
            if (popoverWidth < 300) {
                popoverParent.width(300);
                popoverWidth = popoverParent.width();
            }
            var popoverLeft = popoverParent.offset().left;
            var diff = popoverLeft + popoverWidth - containerLeft - containerWidth;
            if (diff > 0) {
                var pos = popoverParent.position();
                pos.left -= diff;
                popoverParent.css(pos);
            }
            return popoverParent;
        }

        $(document).ready(function() {

            // $('.enhanced-profile-page-header a.j-avatar').append("<i class='far fa-edit'></i>");
            $(document).on('click', '#enhanced-profile-profile-points-outer', function(e) {
                var $target = $(e.currentTarget),
                    $legend = $target.find('.js-acclaim-legend');
                if ($legend.length) {
                    rePositionProfilePointsPopover($legend.popover({
                        context: $target,
                        putBack: true,
                        destroyOnClose: false
                    }).parent()).css('z-index', 4); // under skinny nav of 5
                }
            });
            var personalProfileFieldsMaximum = $('.enhanced-profile-data').attr('data-maximum');
            $('.enhanced-profile-data li').slice(0, personalProfileFieldsMaximum).show();
            $('.enhanced-profile-data-show-more').on('click', function(e) {
                $('.enhanced-profile-data li').show();
                $('.enhanced-profile-data-show-more').hide();
                $('.enhanced-profile-data-hide-more').show();
            });

            $('.enhanced-profile-data-hide-more').on('click', function(e) {
                $(this).hide();
                $('.enhanced-profile-data-show-more').show();
                $('.enhanced-profile-data li').slice(personalProfileFieldsMaximum).hide();
            });

            $('.enhanced-profile-blog-posts-show-more').on('click', function(e) {
                var blogUrl = $(this).attr('data-url');
                location.href = blogUrl;
            });

            $('.visibility-switch').on('click', function(e) {
                var eye = $(this).parents('.visibility-block');
                var component = $(this).attr('data-component');
                eye.toggleClass('disabled');
                var visibility;
                if (eye.hasClass('disabled')) {
                    visibility = false;
                } else {
                    visibility = true;
                }
                setComponentVisibility(component, visibility);
            });

            $('.sub-header-value.header-background').on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                if ($(this).siblings(".j-pop:not(.active)").length) {
                    $j('#j-main-wrapper').click();
                    $(this).siblings(".j-pop:not(.active)").show().addClass("active");
                } else {
                    $(this).siblings(".j-pop.active").hide().removeClass("active");
                }
            });
            $('#j-main-wrapper').on('click', function(e) {
                if (!$(e.target).parents('#enhanced-profile-header-background-options').length && $(".j-pop.active").is(':visible')) {
                    $(".j-pop.active").hide().removeClass("active");
                    if (!$(e.target).closest('.background-image-container').length) {
                        $('.enhanced-profile-page-header').removeClass('background-image-hover');
                    }
                }
            });
            if (isHeaderBackgroundConfigurable) {
	            $('div.background-image-container').mouseenter(function() {
	                $('.enhanced-profile-page-header').addClass('background-image-hover');
	            });
	            $('div.background-image-container').mouseleave(function(event) {
	                if (!$(event.toElement || event.relatedTarget).closest('#enhanced-profile-header-background-options').length && !$('div.header-background').is(':visible')) {
	                    $('.enhanced-profile-page-header').removeClass('background-image-hover');
	                }
	            });
            }
            $('[data-key]').on('click', function(e) {
                e.preventDefault();
                var target = $(e.target);
                var key = target.data('key');
                if (!key || key === 'bgRepeat') {
                    target.closest('.button-list').find('label.checked').removeClass('checked');
                    target.closest('li.button-list-item').find('label').addClass('checked');
                } else if (!target.parent().hasClass('active')) {
                    $('.j-submenu-body .active').removeClass('active');
                    $(target.data('target')).addClass('active');

                    $('.header-background .j-tabbar .active').removeClass('active');
                    target.parent().addClass('active');
                }
            });

            function setBackgroundImage(url, backgroundRepeat) {
                var container = $('div.background-image-container');
                container.css('background-image', 'url(' + url + ')');
                container.css('background-repeat', backgroundRepeat);
                container.css('background-size', backgroundRepeat ? 'auto' : '');
            }

            $('.user-header-background').on('click', function() {
                var selectedOption = $(this).data("background-key");
                var selectedBackgroundUrl = $(this).data("value");
                $(".user-header-background.j-selected").removeClass("j-selected");
                $(this).addClass("j-selected");
                setBackgroundImage(selectedBackgroundUrl, '');
                $.ajax({
                    type: 'POST',
                    url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/profile/header-background/' + selectedOption,
                    contentType: "application/json; charset=utf-8"
                });
            });

            $("#j-static-file-upload").on('change', handleFileChange);

            $('.apply-custom-image').on('click', async function() {
                var file = $("#j-static-file-upload")[0].files[0];
                if (file) {
                    initBannerErrorDiv();
                    clearBannerErrorMessages();

                    var backgroundRepeat = $j("label[data-key='bgRepeat'].checked").data('value');

                    let imagesEnabled = await getImageProperty('feature.images.enabled');
                    if('error' in imagesEnabled) {
                        showBannerErrorMessage("Something went wrong while processing image upload!" + imagesEnabled.errorObj);
                    	return;
                    }
                    if(imagesEnabled.value == false) {
                        showBannerErrorMessage("Image uploads are not enabled on this instance!");
                    	return;
                    }

                    let allowedImageTypes = await getImageProperty("feature.images.allowed_types");
                    if('error' in allowedImageTypes) {
                        showBannerErrorMessage("Something went wrong while processing image upload!" + allowedImageTypes.errorObj);
                    	return;
                    }
                    if(allowedImageTypes.value == ""){
                        showBannerErrorMessage("File type not supported!");
                    	return;
                    }
                    else if(allowedImageTypes.value == "*") {}
                    else {
                        let types = allowedImageTypes.value.split(",");
                        let uploadedFileType = file.type;
                        if(uploadedFileType == 'image/jpeg') uploadedFileType = 'image/jpg';

                        if(types[0] == '-') {
                            if(types.includes(uploadedFileType)) {
                                showBannerErrorMessage("File type not supported!");
                    	        return;
                            }
                        }
                        else {
                            if(!types.includes(uploadedFileType)) {
                                showBannerErrorMessage("File type not supported!");
                    	        return;
                            }
                        }
                    }

                    let imageMaxSize = await getImageProperty('feature.images.max.size');
                    if('error' in imageMaxSize) {
                        showBannerErrorMessage("Something went wrong while processing image upload!" + imageMaxSize.errorObj);
                    	return;
                    }
                    if((file.size/1000) > imageMaxSize.value) {
                        showBannerErrorMessage("Max image size permitted is "  + imageMaxSize.value + "KB !");
                    	return;
                    }

                    let imageMaxHeight = await getImageProperty('feature.images.max.height');
                    if('error' in imageMaxHeight) {
                        showBannerErrorMessage("Something went wrong while processing image upload!" + imageMaxHeight.errorObj);
                    	return;
                    }
                    let imageMaxWidth = await getImageProperty('feature.images.max.width');
                    if('error' in imageMaxWidth) {
                        showBannerErrorMessage("Something went wrong while processing image upload!" + imageMaxWidth.errorObj);
                    	return;
                    }

                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function (e) {
                      var image = new Image();
                      image.src = e.target.result;
                      image.onload = function () {
                        var height = this.height;
                        var width = this.width;
                        if((height > imageMaxHeight.value) || (width > imageMaxWidth.value)) {
                            showBannerErrorMessage("Max Height X Width allowed (pixels): " + imageMaxHeight.value + " X " + imageMaxWidth.value);
                            return;
                        }
                                    
                        uploadFileAndSetBackground(file, backgroundRepeat);
                    };
                    };

                } else {
                    var backgroundRepeat = $("label[data-key='bgRepeat'].checked").data('value');
                    $j.ajax({
                        type: 'PUT',
                        url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/profile/custom-header-background?repeat='
                                + backgroundRepeat,
                        success: function (response) {
                            setBackgroundImage(response, backgroundRepeat);
                            $(".user-header-background.j-selected").removeClass("j-selected");
                        },
                        error: function (error) {
                            console.log(error);
                        }
                    });
                }
            });

            function initBannerErrorDiv() {
                let errorMessageDiv = document.getElementById("eupp-banner-error-message");
                if(errorMessageDiv == null) {
                    let fileUploader = document.getElementById('j-static-file-upload');

                    let newErrMsgDiv = document.createElement("div");
                    newErrMsgDiv.style.color = 'red';
                    newErrMsgDiv.style.display = 'none';
                    newErrMsgDiv.id = "eupp-banner-error-message";

                    fileUploader.parentNode.prepend(newErrMsgDiv);
                }
            }

            function clearBannerErrorMessages() {
                let errorMessageDiv = document.getElementById("eupp-banner-error-message");
                errorMessageDiv.innerText = "";
                errorMessageDiv.style.display = 'none';
            }

            function showBannerErrorMessage(errorMessage) {
                let errorMessageDiv = document.getElementById("eupp-banner-error-message");
                errorMessageDiv.innerText = errorMessage;
                errorMessageDiv.style.display = 'block';
            }

			async function getImageProperty(propertyName) {
				try {
					let prop = await fetch('/api/core/v3/metadata/properties/' + propertyName);
					prop = await prop.json();
					return prop;
				}
				catch(e) {
					return {
						error: true,
						errorObj: e
					};
				}
			}

			function uploadFileAndSetBackground(file, backgroundRepeat) {
				var tokenName = "jive.ps.enhanced.user.profile.plugin." + Math.floor((Math.random()*100000.0)+1) + ".token";
				var imageUploader = buildImageUploader(tokenName, file, backgroundRepeat);
				return addToken(tokenName)
					.pipe(imageUploader)
					.then(function(resp) {
						setBackgroundImage(resp, backgroundRepeat);
						$(".user-header-background.j-selected").removeClass("j-selected");
					});
			} // uploadFileAndSetBackground

			function addToken(tokenName) {
				return $.ajax({
					url: TOKEN_ENDPOINT + tokenName,
					beforeSend:function (req) {
						req.setRequestHeader("X-J-Token", window.parent._jive_auth_token);
					},
					type: 'POST',
					dataType: 'json'
				}).promise();
			} // addToken

            function buildImageUploader(tokenName, file, backgroundRepeat) {
				return function(tokens) {
					var tokenVal = tokens[0];

					var $fakeHiddenForm = $('<form id="fakeHiddenForm" action="'+IMAGE_ENDPOINT+'" method="post"></form>');

					var formData = new FormData($fakeHiddenForm.get(0));
					formData.append('filename', file);
					formData.append('jive.token.name', tokenName);
					formData.append(tokenName, tokenVal);

					return $.ajax({
						url: IMAGE_ENDPOINT + '?fileName='+ encodeURIComponent(file.name) + '&repeat=' + backgroundRepeat,
						type: 'POST',
						data: formData,
						cache: false,
						contentType: false,
						processData: false
					}).promise();
				};
			} // buildImageUploader

            renderTooltipLinks();

        });

        $(document).ready(function() {
            if (enhancedprofile && jive.shared.DatePicker) {
                var datePicker = new jive.shared.DatePicker.View({"locale": window._jive_locale || _jive_datepicker_locale});
                datePicker.addDependentDatePickers(".form-template #start", ".form-template #end");
                datePicker.hide();
                $j(".form-template #start").datepicker("option", "changeMonth", true )
                $j(".form-template #start").datepicker("option", "changeYear", true )
                $j(".form-template #end").datepicker("option", "changeMonth", true )
                $j(".form-template #end").datepicker("option", "changeYear", true )
                $( ".form-template #start" ).datepicker( "option", "maxDate", "-1d" );
                $( ".form-template #end" ).datepicker( "option", "maxDate", "+0d" );
                $( ".form-template #start" ).datepicker( "option", "yearRange", "c-100:c" );
                $( ".form-template #end" ).datepicker( "option", "yearRange", "c-100:c" );
                $( ".form-template #start" ).datepicker( "option", "altField", ".form-template #starttimestamp" );
                $( ".form-template #end" ).datepicker( "option", "altField", ".form-template #endtimestamp" );
                $( ".form-template #start" ).datepicker( "option", "altFormat", "yy-mm-dd" );
                $( ".form-template #end" ).datepicker( "option", "altFormat", "yy-mm-dd" );

                $(document).on('click', '#add-item', function(e) {
                    $form = $(".form-template")
                    $(".form-template #index").val("-1")
                    $(".form-template #company").val("")
                    $(".form-template #location").val("")
                    $(".form-template #title").val("")
                    datePicker.setDate(".form-template #start", new Date())
                    datePicker.setDate(".form-template #end", new Date())
                    $(".form-template #end").prop("disabled", false)
                    $(".form-template #present-job").prop("checked", false)
                    $(".form-template #description").val("")
                    $(".profile-data.experience").hide("slide", { direction: "left"}, 300, function() {
                        $(".form-template").show("slide", { direction: "right" }, 300)
                    });
                });


                $(document).on('click','.experience-show-more', function(e) {
                    $(this).siblings(".experience-description").removeClass("short");
                    $(this).siblings(".experience-hide-more").show();
                    $(this).hide()
                })
                $(document).on('click','.experience-hide-more', function(e) {
                    $(this).siblings(".experience-description").addClass("short");
                    $(this).siblings(".experience-show-more").show();
                    $(this).hide()
                })

                $(document).on('click','.edit-switch', function(e) {
                    $experienceEntry = $(this).parent().parent();
                    $form = $(".form-template");
                    $(".form-template #index").val($experienceEntry.data("index"));
                    $(".form-template #company").val($experienceEntry.data("company"));
                    $(".form-template #location").val($experienceEntry.data("location"));
                    $(".form-template #title").val($experienceEntry.data("title"));
                    datePicker.setDate(".form-template #start", $experienceEntry.data("start"));
                    if ($experienceEntry.data("end")=="") {
                        datePicker.setDate(".form-template #end", new Date());
                        $(".form-template #present-job").prop("checked", true);
                        $(".form-template #end").prop("disabled", true);
                    } else {
                        datePicker.setDate(".form-template #end", $experienceEntry.data("end"));
                        $(".form-template #present-job").prop("checked", false);
                        $(".form-template #end").prop("disabled", false);
                    }
                    $(".form-template #description").val($experienceEntry.data("description"));
                    $(".profile-data.experience").hide("slide", { direction: "left"}, 300, function() {
                        $(".form-template").show("slide", { direction: "right" }, 300);
                    });
                });

                $('#cancel-experience').on('click', function() {
                    $(".form-template").hide("slide", { direction: "right"}, 300, function() {
                        $(".profile-data.experience").show("slide", { direction: "left" }, 300)
                    });
                })


                $('#present-job').on('change', function() {
                    if (this.checked) {
                        $('#end').prop("disabled", true)
                    } else {
                        $('#end').prop("disabled", false)
                    }
                })

                $(document).on('click', ".delete-switch", function() {
                    $experienceEntry = $(this).parent().parent();
                    var entryIndex = $experienceEntry.data("index");
                    $.ajax({
                        type: 'DELETE',
                        url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/experience/' + entryIndex,
                        contentType: "application/json; charset=utf-8",
                        success: function(data, textStatus, xhr) {
                            refreshExperience()
                        },
                        error: function(jqXHR, textStatus, errorThrown){
                            console.log( "Error removing the value", textStatus, errorThrown );
                        }
                    });
                });


                $('#submit-experience').on('click', function() {
                    var entry = {
                        "company": $(".form-template #company").val(),
                        "location": $(".form-template #location").val(),
                        "title": $(".form-template #title").val(),
                        "startTimestamp": $(".form-template #starttimestamp").val(),
                        "endTimestamp": $('#present-job').is(':checked') ? '' : $(".form-template #endtimestamp").val(),
                        "description": $(".form-template #description").val()
                    };
                    if (!entry.company || !entry.location || !entry.title) {
                        return;
                    }
                    $(".form-template").hide("slide", { direction: "right"}, 300, function() {
                        $(".profile-data.experience").show("slide", { direction: "left" }, 300)
                    });

                    var postUrl = window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/experience';
                    var entryIndex = $(".form-template #index").val();
                    if (entryIndex != '-1') {
                        postUrl += '/' + entryIndex;
                    }
                    $.ajax({
                        type: 'POST',
                        url: postUrl,
                        contentType: "application/json; charset=utf-8",
                        data: JSON.stringify(entry),
                        success: function(data, textStatus, xhr) {
                            refreshExperience()
                        },
                        error: function(jqXHR, textStatus, errorThrown){
                            console.log( "Error changing the value", textStatus,errorThrown );
                        }
                    });
                    return false;
                });

                refreshExperience = function () {
                    $.ajax({
                        type: 'GET',
                        url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/experience/' + _jive_current_user.ID,
                        contentType: "application/json; charset=utf-8",
                        success: function(data, textStatus, xhr) {
                            $(".profile-data.experience").html("");
                            $.each(data, function(index, experienceEntry) {
                                var description = experienceEntry.description ? experienceEntry.description.replace(/\r|\n/g, '<br>') : experienceEntry.description;
                                var editEntryTitle = i18n.jsGetMsg("enhancedprofile.edit.this.entry");
                                var removeEntryTitle = i18n.jsGetMsg("enhancedprofile.remove.this.entry");
                                $("<section>").addClass("experience-entry").data(experienceEntry).append(
                                    $("<section>").addClass("display").append(
                                        $('<label class="delete-switch control-icon" title="' + removeEntryTitle + '"><i class="far fa-trash-alt "></i></label>'),
                                        $('<label class="edit-switch control-icon" title="' + editEntryTitle + '"><i class="far fa-edit "></i></label>'),
                                        $('<h4 class="title">'+experienceEntry.title+'</h4>'),
                                        $('<span class="experience-sub-header">').append(
                                            $('<strong>'+experienceEntry.company+'</strong>'),
                                            $('<span>, '+experienceEntry.location+'</span><br>'),
                                            $('<span>'+experienceEntry.start+' - '+ (experienceEntry.end=='' ? 'now' : experienceEntry.end) + ' ('+experienceEntry.duration+')</span>')
                                        ),
                                        $('<div class="experience-description short">' + description + '</div>'),
                                        $(' <div class="experience-show-more">' + i18n.jsGetMsg("enhancedprofile.show.more") + '</div>'),
                                        $(' <div class="experience-hide-more">' + i18n.jsGetMsg("enhancedprofile.show.less") + '</div>')
                                    )
                                ).appendTo($(".profile-data.experience"))
                            })
                            hideShowMoreButton();
                            if (data.length < $(".profile-data.experience").data("max")) {
                                $('<button id="add-item" class="j-btn-global">' + i18n.jsGetMsg("enhancedprofile.addexperience") + '</button>').appendTo($(".profile-data.experience"))
                            }
                        },
                        error: function(jqXHR, textStatus, errorThrown){
                            console.log( "Error changing the value", textStatus,errorThrown );
                        }
                    });
                }

                function hideShowMoreButton() {
                    $(".experience-description").each(function() {
                        if ($(this)[0].scrollWidth <= $(this)[0].offsetWidth &&
                                (!$(this)[0].innerHTML || $(this)[0].innerHTML.indexOf('<br>') === -1)) {
                            $(this).siblings(".experience-show-more").hide();
                        }
                    });
                }
                /* hide unnecessary show more buttons on load */
                hideShowMoreButton();
            }
        });

    });



    define('apps/enhancedprofile/connections', [
        'underscore',
        'jquery',
        'soy!jive.people.connections.connectionsNoContent',
        'soy!enhancedprofile.connectionsList',
        'soy!jive.people.connections.connectionsModal'
    ], function(_, $, noContent, list, modal, targetUser) {

        return jive.oo.Class.extend(function(protect) {

            this.showList = function() {
                var self = this;
                var $list = $('.enhanced-profile-connections-list');
                var connections = getConnections(this.targetUser);
                connections.then(function(cs) {
                    if (cs.length < 1) {
                        $('div.enhanced-profile-tile.connections').hide();
                    } else {
                        if (cs.length > 5) {
                            cs = cs.slice(0, 5);
                            $('.connections-show-all-container').show();
                        }
                        $list.html(
                            list({
                                viewer: _jive_current_user,
                                connections: cs,
                                targetUser: self.targetUser,
                                viewingSelf: _jive_current_user.ID == self.targetUser.id
                            })
                        );
                    }
                });
            };

            protect.init = function(opts) {
                this.targetUser = opts.targetUser;
                this.showList();
            };

            function getConnections(user) {
                var url = '/users/' + user.id + '/related/common';
                return $.getJSON(jive.rest.url(url)).then(_.prop('items'));
            }
        });
    });



    define('apps/enhancedprofile/recent_place_activity', [
        'apps/shared/models/rest_service',
        'lib/jiverscripts/src/conc/promise'
    ], function(RestService, Promise) {
        return RestService.extend(function(protect) {

            // Mix in observable to make this class an event emitter.
            jive.conc.observable(this);

            /**
             * Loads a list of activity for a particular user, using userId
             *
             * @returns {Promise} promise that is fulfilled when the list of activity is ready
             */
            this.list = function(resource, promise) {
                var url = '/__services/v2/rest/activity-stream/list';
                if (!promise) {
                    promise = new Promise();
                }
                return this.commonAjaxRequest(promise, 'POST', {
                    url: url,
                    data: JSON.stringify(resource)
                }).addErrback(function(message, status) {
                    if (status == 401 || status == 403 || status == 4026 || status === 0) {
                        location.reload();
                    }
                }).always(function() {
                    protect.displayGenericErrorMessages = true;
                });
            };
        });
    });
}