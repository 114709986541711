/*
 * @depends template=enhancedprofile.subHeader
*/
window.$j = window.$j || $; // Jive 9.19+ compatibility

if (typeof enhancedprofilepage !== 'undefined') {
    define('apps/enhancedprofile/availability', [
        'jive/i18n'
    ], function(i18n) {
        $j(document).ready(function() {
            // $j('#user-availability').on('change', function() {
            //     var selectedOption = this.value;
            //     $j.ajax({
            //         type: 'POST',
            //         url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/availability/' + selectedOption,
            //         contentType: 'application/json; charset=utf-8',
            //         success: function(data, textStatus, xhr) {
            //             console.log('Successfully changed the value');
            //         },
            //         error: function(jqXHR, textStatus, errorThrown){
            //             console.log('Error changing the value');
            //         }
            //     });
            // });
            $j('.sub-header-value.availability').on('click', function(e) {  
                e.preventDefault();
                e.stopPropagation()
                if ($j(this).find('.j-pop.availability:not(.active)').length) {
                    $j('#j-main-wrapper').click();
                    $j(this).find('.j-pop.availability:not(.active)').show().addClass('active');
                } else {
                    $j(this).find('.j-pop.availability.active').hide().removeClass('active');
                }
            });
            $j('#j-main-wrapper').on('click', function() {
                $j('.j-pop.availability.active').hide().removeClass('active');
            });
            $j('.user-availability').on('click', function() {
                var selectedOption = $j(this).data('value');
                $j('.current-availability').text($j(this).text())
                $j('.user-availability.j-selected').removeClass('j-selected');
                $j(this).addClass('j-selected');
                $j('.current-availability').text($j(this).text())
                $j.ajax({
                    type: 'POST',
                    url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/availability/' + selectedOption,
                    contentType: 'application/json; charset=utf-8',
                    success: function(data, textStatus, xhr) {},
                    error: function(jqXHR, textStatus, errorThrown){
                        console.error('Error changing the value to', selectedOption);
                    }
                });
            });

            $j('.sub-header-value.workinghours').on('click', function(e) {  
                e.preventDefault();
                e.stopPropagation()
                if ($j(this).find('.j-pop.workinghours:not(.active)').length) {
                    $j('#j-main-wrapper').click()
                    $j(this).find('.j-pop.workinghours:not(.active)').show().addClass('active');
                } else {
                    $j(this).find('.j-pop.workinghours.active').hide().removeClass('active');
                }
            });
            $j('input#workinghours').on('click', function(e) {
                e.preventDefault();
                e.stopPropagation()
            });
            $j('#submit-workinghours').on('click', function(e) {
                var workingHours = $j('#workinghours').val();
                if (!!workingHours) {
                    workingHours = workingHours.trim();
                }
                $j.ajax({
                    type: 'POST',
                    url: window._jive_base_absolute_url + '/api/core/ext/enhanced-profile-plugin/v3/changeworkinghours',
                    contentType: 'application/json; charset=utf-8',
                    data: JSON.stringify({
                        'workingHours' : workingHours
                    }),
                    success: function(data, textStatus, xhr) {
                        if (!!workingHours) {
                            $j('.current-workinghours').text(workingHours);
                        } else {
                            $j('.current-workinghours').text(i18n.jsGetMsg('enhancedprofile.workinghours'));
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown){
                        console.log('Error changing the value to', workingHours);
                        $j('#workinghours').val($j('.current-workinghours').text());
                        if (jqXHR.responseJSON.error.status === 400) {
                            alert(jqXHR.responseJSON.error.message);
                        }
                    }
                });
            });
        });
    });
}
