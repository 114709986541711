/*
 * $Revision$
 * $Date$
 *
 * Copyright (C) 1999-2013 Jive Software. All rights reserved.
 *
 * This software is the proprietary information of Jive Software. Use is subject to license terms.
 *
 */

jive.namespace('Expertise'); // Creates the jive.Expertise namespace if it does not already exist.

define('apps/tags/expertise_controller', [
    'jquery',
    'underscore',
    'apps/tags/model/expertise_model',
    'apps/tags/tags_controller',
    'soy!jive.people.profile.modalWrapper',
    'soy!enhancedprofile.expertiseModal',
    'soy!jive.people.profile.endorsementModal',
    'soy!enhancedprofile.expertiseList',
    'soy!jive.people.profile.expertiseTileButton',
    'soy!jive.people.profile.confirmExpertiseDelete'
], function($, _, ExpertiseModel, TagInputController, modalWrapper, expertiseModal, acclaimModal,
    expertiseList, expertiseTileButton, confirmDeleteModal) {

    jive.Expertise.Controller = jive.oo.Class.extend(function(protect) {

        var csstransforms3d = window.styleMedia && window.styleMedia.matchMedium('(-webkit-transform-3d)');
        var $modalWrapper = $(modalWrapper());

        protect.init = function() {
            var controller = this;
            // Set Expertise Tile
            controller.$el = $('#j-expertise-tile');
            // Tile flipped state
            controller.flipped = false;
            // If the current user is viewing self
            controller.viewingSelf = controller.$el.data('viewingSelf');
            controller.isUserAdmin = controller.$el.data('isUserAdmin');
            controller.anonymous = controller.$el.data('anonymous');
            controller.userFirstName = controller.$el.data('userFirstName');
            controller.userName = controller.$el.data('userName');
            controller.userId = controller.$el.data('targetUserId');
            controller.expertiseEnabled = '' + controller.$el.data('expertiseEnabled');
            // Set up events
            controller.bindDomEvents('on', controller.$el);
            controller.showInputMsgTimeout = null;
            //Init Tag precent
            controller.updateTagPercentage(controller.$el);

            controller.expertiseModel = new ExpertiseModel({
                viewingSelf: controller.viewingSelf
            }, controller.$el.data('tagData'));
        };

        protect.bindDomEvents = function(state, element) {
            var controller = this;
            element[state]('click', '.js-flip', _.bind(controller.flip, controller));
            element[state]('click', '.js-endorse-title', _.bind(controller.onClickTitle, controller));
            element[state]('click', '.js-endorse-view-all', _.bind(controller.viewAllEndorsements, controller));
            element[state]('click', '.js-endorse-add-modal', _.bind(controller.addEndorsementInModal, controller));
            element[state]('click', '.js-expertise-add', _.bind(controller.addExpertise, controller));
            element[state]('click', '.js-expertise-view-all', _.bind(controller.viewAllExpertise, controller));
            // Hover
            element[state]('mouseenter', '.js-endorse-title',
                controller.showEndorseControls.curry(controller.viewingSelf));
            element[state]('mouseleave', '.js-endorse-title', controller.hideEndorseControls);
            element[state]('keypress', '.js-endorse-view-all', _.bind(controller.viewAllEndorsements, controller));
            element[state]('keydown', '#endorse-button' ,_.bind(controller.onKeyDownEndorseButton, controller));
            element[state]('keyup', '#endorse-button' ,_.bind(controller.onKeyUpEndorseButton, controller));
            $('#j-expertise-modal').find('.js-tag-input').trigger('tags.resizeInput');
        };

        protect.flip = function(e) {
            var controller = this;

            // In webkit browsers that do not support CSS 3D transforms
            // we will use 2D versions to get the flip effect.  But
            // these browsers do not support the necessary backface
            // visibility CSS rules.  So we emulate those here.
            if ($.browser.webkit && !csstransforms3d) {
                var flipMidpoint = 160;
                setTimeout(function() {
                    controller.$el.find('.card-back').toggle(controller.flipped);
                    controller.$el.find('.card-front').toggle(!controller.flipped);
                }, flipMidpoint);
            }
            // manually set the css of the relatively positioned tile to absolute, so the flipping effect works
            var position = controller.$el.position(),
                width = controller.$el.width(),
                height = controller.$el.height();
            controller.$el.css({
                position: 'absolute',
                width: width,
                height: height,
                top: position.top,
                left: position.left
            });
            controller.$el.find('.card, .card-front, .card-back').css({
                position: 'absolute',
                width: width,
                height: height
            });
            controller.$el.find('.card').toggleClass('flipped', !controller.flipped);
            setTimeout(function() {
                // reset the css of the absolute positioned tile back to default, so the fluid layout works
                controller.$el.css({
                    position: '',
                    width: '',
                    height: '',
                    top: '',
                    left: ''
                });
                controller.$el.find('.card, .card-front, .card-back').css({
                    width: '',
                    height: ''
                });
            }, 400);
            controller.flipped = !controller.flipped;
            if (controller.flipped) {
                controller.initializeInput(controller.$el.find('.js-tag-input'));
            } else {
                controller.updateExpertiseTags();
                controller.$el.find('.js-btn-endorse').focus();
            }
            e.preventDefault();
        };

        protect.initializeInput = function($element) {
            var controller = this;
            $element.closest('form').find('.js-hidden-tag-input').val(
                controller.expertiseModel.getExpertise().expertiseTagList
            );
            if (controller.tagInput) {
                controller.tagInput.teardown();
            }
            controller.tagInput = new TagInputController($element, {
                containerType: controller.$el.data('targetUserType'),
                containerID: controller.$el.data('targetUserId'),
                objectTypes: [3]
            });
            controller.tagInput.addListener('tags.duplicate', _.bind(controller.duplicateTag, controller));
            controller.tagInput.clear();

            // for accessibility. Put the focus on the input field and make esacpe flip the card.
            // trying to mimick popup behavior.
            $element.focus();
            $(document).one('keyup', function(e) {
                if (e.which == 27 && controller.flipped) {
                    controller.flip();
                }
            });
        };

        protect.onClickTitle = function(e) {
            e.preventDefault();
            var controller = this,
                $target = $(e.target);
            if (controller.viewingSelf) {
                controller.removeExpertise(e);
            } else if (controller.isUserAdmin && $target.hasClass('js-expertise-remove')) {
                controller.removeExpertise(e);
            } else if (controller.expertiseEnabled == 'true') {
                if ($target.closest('.js-expertise-item').data('endorsed')) {
                    controller.removeEndorsement(e);
                } else {
                    controller.addEndorsement(e);
                }
            }
        };

        protect.onKeyDownEndorseButton = function (e) {
            const code = e.keyCode || e.which;
            if (code === 9) {
                const siblings = e.target.parentElement.childNodes;
                if (siblings.length >= 2) {
                    siblings[1].classList.remove('active');
                }
            }
        };

        protect.onKeyUpEndorseButton = function(e) {
            const code = e.keyCode || e.which;
            if (code === 9) {
                const siblings = e.target.parentElement.childNodes;
                if (siblings.length >= 2) {
                    siblings[1].classList.add('active');
                }
            }
        };

        protect.addEndorsement = function(e, updateFromModal) {
            e.preventDefault();
            var controller = this,
                expertise = $(e.target),
                expertiseTag = getExpertiseTag(expertise),
                name = getName(controller);
            if (!controller.viewingSelf) {
                controller.expertiseModel.createEndorsement(this.userId, expertiseTag).then(function() {
                    controller.updateExpertiseTags();
                    const i18nMessage = jive.i18n.i18nText(jive.i18n.jsGetMsg('expertise.endorse.adding.other'), [name, expertiseTag]).content;
                    $('<p>' + i18nMessage + '</p>').message({
                        style: 'success'
                    });
                    if (updateFromModal) {
                        controller.viewAllEndorsements(e);
                    }
                });
            }
        };

        protect.addEndorsementInModal = function(e) {
            e.preventDefault();
            this.addEndorsement(e, true);
        };

        protect.removeEndorsement = function(e) {
            e.preventDefault();
            var controller = this,
                expertise = $(e.target),
                expertiseTag = getExpertiseTag(expertise),
                name = getName(controller);

            controller.expertiseModel.destroyEndorsement(controller.userId, expertiseTag).then(function() {
                const i18nMessage = jive.i18n.i18nText(jive.i18n.jsGetMsg('expertise.endorse.removing.other'), [name, expertiseTag]).content;
                $('<p>' + i18nMessage + '</p>').message({
                    style: 'success'
                });
                controller.updateExpertiseTags();
            });
        };

        protect.viewAllEndorsements = function(e) {
            e.preventDefault();
            var expertiseTag = getExpertiseTag($(e.target)),
                controller = this,
                tags = controller.expertiseModel.getExpertise();
            controller.expertiseModel.getEndorsementsForExpertise(controller.userId,
                expertiseTag).then(function(users) {
                var modal = getEndorseModal({
                    users: users,
                    activityType: 'expertise',
                    expertiseTag: expertiseTag,
                    expertiseCount: tags.tagCount.length,
                    viewingSelf: controller.viewingSelf,
                    userFirstName: controller.userFirstName,
                    currentUserID: getViewingUser().ID,
                    anonymous: controller.anonymous
                });
                modal.lightbox_me(controller.getModalOptions());
            });
        };

        protect.addExpertise = function(e) {
            e.preventDefault();
            var controller = this,
                isTile = $(e.target).is('.js-tile-add'),
                message = '',
                continueAdding = function() {
                    var tags = controller.tagInput.getTagsFromBadges(),
                        name = getName(controller);
                    controller.tagInput.removeListener('tags.add', continueAdding);
                    if (tags && tags.length > 0) {
                        controller.expertiseModel.createExpertise(controller.userId, tags).then(function() {
                            if (!isTile) {
                                controller.updateExpertiseTags();
                            } else {
                                controller.$el.find('.j-tag').remove();
                                controller.flip(e);
                            }
                            if (controller.viewingSelf) {
                                const i18nMessage = jive.i18n.jsGetMsg('expertise.adding.added.you');
                                message = '<p>' + i18nMessage + '</p>';
                            } else {
                                const i18nMessage = jive.i18n.i18nText(jive.i18n.jsGetMsg('expertise.adding.added.other'), [name]).content;
                                message = '<p>' + i18nMessage + '</p>';
                            }
                            $(message).message({
                                style: controller.viewingSelf ? 'success' : 'warn'
                            });
                            controller.tagInput.getElement().data('suggestBox').teardown();
                            if (!isTile) {
                                controller.initializeInput($(e.target).closest('form').find('.js-tag-input'));
                            }
                        });
                    }
                };
            if (controller.tagInput.getInputVal() != '') {
                // attempt to add any hanging tags
                controller.tagInput.addListener('tags.add', continueAdding);
                controller.tagInput.insertTags(controller.tagInput.getInputVal(), true);
            } else {
                continueAdding();
            }

//            controller.$el.find('.j-expertise-list').style.overflow = "auto";
        };

        protect.removeExpertise = function(e) {
            e.preventDefault();
            var controller = this,
                $deleteBtn = $(e.target),
                tag = $deleteBtn.closest('.js-endorse-info').data('expertise'),
                parentLightbox = isModalVisible() ? getModal() : false,
                $confirmModal = $(confirmDeleteModal({
                    expertiseEnabled: controller.expertiseEnabled
                }));
            $confirmModal.lightbox_me({
                destroyOnClose: true,
                centered: true,
                parentLightbox: parentLightbox,
                onLoad: function() {
                    $confirmModal.on('click', '#expertise-delete-submit-button', function(e2) {
                        controller.expertiseModel.destroyExpertise(controller.userId, tag).then(function() {
                            $deleteBtn.closest('.js-expertise-item').fadeOut(function() {
                                $(this).remove();
                                $confirmModal.trigger('close');
                            });
                            controller.updateExpertiseTags();
                        });
                        e2.preventDefault();
                    });
                }
            });
        };

        protect.viewAllExpertise = function(e) {
            e.preventDefault();
            var controller = this,
                tags = controller.expertiseModel.getExpertise(),
                modal = getExpertiseModal({
                    expertiseEnabled: controller.expertiseEnabled,
                    tags: tags.tagCount,
                    tagList: tags.expertiseTagList,
                    userFirstName: controller.userFirstName,
                    anonymous: controller.anonymous,
                    viewingSelf: controller.viewingSelf,
                    isUserAdmin: controller.isUserAdmin
                });
            modal.lightbox_me(controller.getModalOptions());
            var $modalBody = modal.find('.j-expertise'),
                suggestionsHidden = false;
            $modalBody.on('scroll.autoHideSuggestions', function() {
                var $suggestionPop = modal.find('.js-pop');
                if ($suggestionPop.length) {
                    var headerHeight = modal.find('header').outerHeight(),
                        inputTop = modal.find('form ul').position().top,
                        inputHeight = modal.find('form ul').outerHeight(),
                        arrowBump = 24;
                    if (inputTop + inputHeight < 0) {
                        suggestionsHidden = true;
                        $suggestionPop.hide();
                    } else {
                        if (suggestionsHidden) {
                            suggestionsHidden = false;
                            $suggestionPop.show();
                        }
                        $suggestionPop.css('top', inputTop + inputHeight + headerHeight + arrowBump + 'px');
                    }
                }
            });
            controller.initializeInput(modal.find('.js-tag-input'));
            controller.updateTagPercentage(modal);
        };

        protect.updateExpertiseTags = function() {
            var controller = this,
                limit = 5,
                modalList = getModal().find('.j-expertise-list'),
                modalInput = getModal().find('.js-hidden-tag-input'),
                tileList = controller.$el.find('.j-expertise-list'),
                currentButton = controller.$el.find('footer'),
                tags = controller.expertiseModel.getExpertise();
            modalInput.val(tags.expertiseTagList);
            modalList.replaceWith(expertiseList({
                tagCount: tags.tagCount,
                viewingSelf: controller.viewingSelf,
                isUserAdmin: controller.isUserAdmin,
                userFirstName: controller.userFirstName,
                userName: controller.userName,
                expertiseEnabled: controller.expertiseEnabled
            }));
            tileList.replaceWith(expertiseList({
                tagCount: tags.tagCount,
                viewingSelf: controller.viewingSelf,
                isUserAdmin: controller.isUserAdmin,
                tagLimit: limit,
                userFirstName: controller.userFirstName,
                userName: controller.userName,
                expertiseEnabled: controller.expertiseEnabled
            }));
            controller.updateTagPercentage(getModal());
            controller.updateTagPercentage(controller.$el);
            currentButton.html(expertiseTileButton({
                tagCount: tags.tagCount,
                viewingSelf: controller.viewingSelf,
                expertiseEnabled: controller.expertiseEnabled
            }));
        };


        protect.showEndorseControls = function() {
            $(this).find('.js-endorse-controls').addClass('active');
        };

        protect.hideEndorseControls = function() {
            $(this).find('.js-endorse-controls').removeClass('active');
        };

        protect.duplicateTag = function(tag) {
            var controller = this,
                $msgContainer = controller.tagInput.getElement().closest('form').siblings('.js-expertise-input-msg'),
                i18nMessage = '';
            if (controller.viewingSelf) {
                i18nMessage = jive.i18n.i18nText(jive.i18n.jsGetMsg('expertise.adding.duplicate.you'), [tag]).content;
                $msgContainer.text(i18nMessage);
            } else {
                if (!_.find(controller.expertiseModel.getExpertise().tagCount, function(tagItem) {
                        return tagItem.tag == tag && tagItem.endorsed;
                    })) {
                    // implicit endorsement of expertise
                    controller.expertiseModel.createEndorsement(controller.userId, tag);
                    controller.updateExpertiseTags();
                    i18nMessage = jive.i18n.i18nText(jive.i18n.jsGetMsg('expertise.adding.duplicate.other.endorse'), [controller.userFirstName, tag]).content;
                } else {
                    // already endorsed
                    i18nMessage = jive.i18n.i18nText(jive.i18n.jsGetMsg('expertise.adding.duplicate.other.endorsed'), [controller.userFirstName, tag]).content;
                }
                $msgContainer.html(i18nMessage);
            }
            $msgContainer.show();
            window.clearTimeout(controller.showInputMsgTimeout);
            controller.showInputMsgTimeout = window.setTimeout(function() {
                $msgContainer.fadeOut();
            }, 4000);
        };


        protect.getModalOptions = function() {
            return {
                destroyOnClose: true,
                centered: true,
                initialFocusSelector: '.js-tag-input',
                preClose: _.bind(this.bindDomEvents.curry('off', getModal()), this),
                onLoad: !isModalVisible() ? _.bind(this.bindDomEvents.curry('on', getModal()), this) : $.noop
            };
        };

        protect.updateTagPercentage = function(element) {
            var expertiseList = element.find('.j-expertise-item'),
                max = +$(expertiseList[0]).find('.j-endorse-count').text();
            expertiseList.each(function() {
                var expertise = $(this),
                    count = +expertise.find('.j-endorse-count').text(),
                    percent = Math.floor((count / max) * 100);
                expertise.find('.j-endorse-percent').css('width', percent + '%');
            });
        };


        // Helper methods
        function getExpertiseTag($element) {
            return $element.closest('.j-endorse-info').data('expertise');
        }

        function getName(controller) {
            var name;
            if (controller.userFirstName && controller.userFirstName.length > 0) {
                name = controller.userFirstName;
            } else {
                name = controller.userName;
            }
            return name;
        }

        function getViewingUser() {
            return _jive_current_user;
        }

        function getModal() {
            if ($modalWrapper.length < 1) {
                $modalWrapper = $(modalWrapper());
            }
            return $modalWrapper;
        }

        function isModalVisible() {
            return getModal().is(':visible');
        }

        function getExpertiseModal(data) {
            return getModal().html(expertiseModal(data));
        }

        function getEndorseModal(data) {
            return getModal().html(acclaimModal(data));
        }

    })();
    return jive.Expertise.Controller;
});
